import {
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { Chart } from '../../../charts';
import { splitTime, statusLabels } from '../../../shared/utils';
import { useDetailChartsGql } from '../../graphql';
import { Count, Label, MetricLabelWrapper } from '../../metricsCard.styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { MdInfoOutline } from 'react-icons/md';
import AppTooltip from '../../../appTooltip';
import { BsGear } from 'react-icons/bs';
import SetGoalModal from './SetGoalModal';
import { Link } from 'react-router-dom';
import ViewSwitcher from '../../../viewSwitcher';
import { viewSwitchObj } from '../../metricsCard.utils';
import { FaBullseye } from 'react-icons/fa';
import { useCallback, useRef } from 'react';
import { toPng } from 'html-to-image';
import Logo from '../../../shared/assets/DD-Logo.svg';
import ImageDown from '../../../shared/assets/image-down.svg';
import { useToastHook } from '../../../appToast';
import { Loader } from '../../../appLoader';

interface MetricDashboardProps {
  selected?: any;
  selectedTeam: string;
  ticketType?: string;
  setType: (type: string) => void;
  type?: string;
  repos?: string[];
  queryKey: string;
}

const ignoreToggleArr = [
  'TOP_REVIEWER_DISTRIBUTION',
  'PR_AGEING_DISTRIBUTION',
  'REVIEW_RESPONSIVENESS_INDEX',
  'ISSUE_CYCLE_TIME_DIST',
  'CHANGE_AGING_DISTRIBUTION',
  'ISSUE_PROGRESSION_RATE',
  'ISSUE_LIFECYCLE_DIST',
];

export const MetricDashboard = ({
  selected,
  selectedTeam,
  ticketType,
  type,
  repos,
  queryKey,
}: MetricDashboardProps) => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const setGoalPopup = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [viewToggle, setViewToggle] = useState('Week');
  const [newToast] = useToastHook();

  const { data, isFetching } = useDetailChartsGql(
    selected.startDate,
    selected.endDate,
    type as string,
    '',
    selectedTeam,
    selected?.sprintId,
    ticketType || '',
    repos || [],
    viewToggle.toUpperCase()
  );

  const modifiedData = ['LEAD_TIME_BREAKDOWN'].includes(
    data?.chartMetadata?.chartKey
  )
    ? {
        ...data,
        chartMetadata: { ...data?.chartMetadata, ylabel: 'Hour' },
        data: data?.data?.map((item: any) => ({
          ...item,
          codingTime: Number(item.codingTime) / 60,
          deployTime: Number(item.deployTime) / 60,
          mergeTime: Number(item.mergeTime) / 60,
          pickupTime: Number(item.pickupTime) / 60,
        })),
        keys: data?.keys?.map((key: any) =>
          key.name === 'Minutes' ? { ...key, name: 'Hours' } : key
        ),
      }
    : ['MEAN_TIME_TO_RECOVERY_DISTRIBUTION'].includes(
        data?.chartMetadata?.chartKey
      )
    ? {
        ...data,
        chartMetadata: { ...data?.chartMetadata, ylabel: 'Hour' },
        data: data?.data?.map((item: any) => ({
          ...item,
          High: item.High
            ? parseFloat(String(Number(item.High) / 60)).toFixed(1)
            : 0,
          Low: item.Low
            ? parseFloat(String(Number(item.Low) / 60)).toFixed(1)
            : 0,
          Lowest: item.Lowest
            ? parseFloat(String(Number(item.Lowest) / 60)).toFixed(1)
            : 0,
          Medium: item.Medium
            ? parseFloat(String(Number(item.Medium) / 60)).toFixed(1)
            : 0,
          Critical: item.Critical
            ? parseFloat(String(Number(item.Critical) / 60)).toFixed(1)
            : 0,
          Highest: item.Highest
            ? parseFloat(String(Number(item.Highest) / 60)).toFixed(1)
            : 0,
        })),
        keys: data?.keys?.map((key: any) =>
          key.name === 'Minutes' ? { ...key, name: 'Hours' } : key
        ),
      }
    : ['ISSUE_LIFECYCLE_DIST'].includes(data?.chartMetadata?.chartKey)
    ? {
        ...data,
        data: data?.data?.map((item: any) => ({
          ...item,
          label: statusLabels[item.label],
          value: parseFloat(item.value),
          avgValue: parseFloat(item.avgValue),
        })),
      }
    : data;

  const [show, setShow] = useState(true);

  const ref = useRef<HTMLDivElement>(null);
  const [mergedImage, setMergedImage] = useState<string | null>(null);
  const [imageDownloadLoading, setImageDownloadLoading] = useState(false);

  const downloadChart = useCallback(
    (chartName: string) => {
      if (ref.current === null) {
        return;
      }

      setImageDownloadLoading(true);

      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const img = document.createElement('img') as HTMLImageElement;
          img.src = dataUrl;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) return;
            canvas.width = img.width;
            canvas.height = img.height;

            context.drawImage(img, 0, 0);
            const logoImg = document.createElement('img') as HTMLImageElement;
            logoImg.src = Logo;
            logoImg.onload = () => {
              const logoWidth = logoImg.width / 4;
              const logoHeight = logoImg.height / 4;
              const logoX = canvas.width - logoWidth - 17;
              const logoY = 90;

              context.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);

              const mergedDataUrl = canvas.toDataURL('image/png');
              setMergedImage(mergedDataUrl);

              const link = document.createElement('a');
              link.download = `${chartName}.png`;
              link.href = mergedDataUrl;
              link.click();
              setImageDownloadLoading(false);
              setShow(true);
            };
          };
        })
        .catch((err) => {
          setShow(true);
          setImageDownloadLoading(false);
          newToast({
            message: 'Something went wrong while downloading.',
            status: 'error',
          });
        });
    },
    [ref]
  );

  return (
    <>
      <Box
        borderRadius="4px"
        border="0.5px solid rgba(0, 0, 0, 0.1)"
        boxShadow="md"
        bg={'white'}
        ref={ref}
      >
        <Box mx={3} mt={2}>
          <Flex w="full" justify={'space-between'}>
            <Box w="100%">
              <Flex align="center" justify="space-between">
                <Flex align="center">
                  <Text
                    as="span"
                    fontSize="lg"
                    color="text.secondary"
                    fontWeight="semibold"
                    fontFamily="heading"
                    mr={1}
                    minW={'max-content'}
                  >
                    {modifiedData?.chartMetadata?.chartTitle}
                  </Text>
                  {modifiedData?.chartMetadata?.info && (
                    <AppTooltip
                      content={
                        <Text
                          fontSize="sm"
                          color="text.secondary"
                          fontWeight="normal"
                          fontFamily="heading"
                        >
                          {modifiedData?.chartMetadata?.info}
                        </Text>
                      }
                      placement="bottom"
                      trigger="hover"
                    >
                      <MdInfoOutline size={16} color={colors.text.secondary} />
                    </AppTooltip>
                  )}
                </Flex>
                {modifiedData?.chartDataState === 'NOT_CONFIGURED' ||
                modifiedData?.chartDataState === 'NO_INTEGRATION' ? (
                  <AppTooltip
                    trigger="hover"
                    content={
                      <Flex>
                        <Link to={`${modifiedData?.chartError?.link}`}>
                          <Text
                            color="primary"
                            fontFamily="heading"
                            fontSize="sm"
                            textDecoration={'underline'}
                          >
                            Configure
                          </Text>
                        </Link>
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          color="text.primary"
                          ml={2}
                        >
                          {modifiedData?.chartError?.message}
                        </Text>
                      </Flex>
                    }
                  >
                    <Box>
                      <Button
                        size={'sm'}
                        color={'error'}
                        border="1px"
                        borderColor={'error'}
                        backgroundColor={'white'}
                        fontFamily="heading"
                      >
                        {'why no data?'}
                      </Button>
                    </Box>
                  </AppTooltip>
                ) : (
                  <Flex align="center" gap={2}>
                    {!ignoreToggleArr.includes(
                      modifiedData?.chartMetadata?.chartKey
                    ) && (
                      <ViewSwitcher
                        tabList={viewSwitchObj}
                        currentTab={viewToggle}
                        handleSwitch={setViewToggle}
                        height="25px"
                      />
                    )}

                    {localStorage.getItem('role') !== 'EMPLOYEE' && (
                      <Button
                        size="xs"
                        color="primary"
                        variant="primary"
                        onClick={setGoalPopup.onOpen}
                        leftIcon={<FaBullseye />}
                      >
                        Set Goal
                      </Button>
                    )}
                    {modifiedData?.configLink && (
                      <Button
                        as={Link}
                        size="xs"
                        color="primary"
                        variant="primary"
                        to={modifiedData?.configLink}
                        leftIcon={<BsGear />}
                      >
                        Configure
                      </Button>
                    )}
                  </Flex>
                )}
              </Flex>

              {modifiedData?.threshold && (
                <MetricLabelWrapper>
                  <Count
                    color={
                      modifiedData?.threshold?.color || colors.text.primary
                    }
                  >
                    {modifiedData?.threshold === null
                      ? '_'
                      : modifiedData?.threshold?.value === 'NaN'
                      ? '_'
                      : modifiedData?.threshold?.value === null
                      ? '_'
                      : modifiedData?.chartMetadata?.chartKey ===
                          'PR_CYCLE_TIME_BREAKDOWN' ||
                        modifiedData?.chartMetadata?.chartKey ===
                          'LEAD_TIME_BREAKDOWN' ||
                        modifiedData?.chartMetadata?.chartKey ===
                          'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
                      ? splitTime(parseFloat(modifiedData?.threshold?.value))
                      : String(
                          parseFloat(modifiedData?.threshold?.value).toFixed(1)
                        )}
                  </Count>
                  <Label>{modifiedData?.threshold?.title}</Label>
                </MetricLabelWrapper>
              )}
              <Box marginBottom="20px" w="50%">
                <Text color="text.secondary" fontFamily="heading" fontSize="sm">
                  {modifiedData?.threshold?.desc}
                </Text>
              </Box>
            </Box>
          </Flex>

          {isFetching ? (
            <Box position={'relative'}>
              <Skeleton
                height="300px"
                width="100%"
                style={{ marginTop: '20px' }}
              />
              <Spinner position={'absolute'} top={'45%'} left={'47%'} />
            </Box>
          ) : modifiedData?.chartDataState === 'NO_INTEGRATION' ? (
            <Box
              w="full"
              height="300px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={modifiedData?.chartMetadata?.zsImage || ''}
                style={{ position: 'relative' }}
              />
              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {modifiedData?.chartMetadata?.source && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {modifiedData?.chartMetadata?.source === 'ISSUE TRACKING'
                        ? 'Jira'
                        : modifiedData?.chartMetadata?.source === 'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              w="100%"
              height="300px"
              marginTop="20px"
              overflowY={'auto'}
              overflowX={'hidden'}
              position={'relative'}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              {!isFetching && show && (
                <Tooltip label="Download Chart" hasArrow>
                  <Button
                    variant={'unstyled'}
                    size={'xs'}
                    mx={2}
                    position={'absolute'}
                    right={2}
                    zIndex={99}
                    onClick={() => {
                      setShow(false);
                      downloadChart(
                        modifiedData?.chartMetadata?.chartTitle ?? 'Chart'
                      );
                    }}
                  >
                    <Image w={5} h={5} src={ImageDown} />
                  </Button>
                </Tooltip>
              )}
              {modifiedData?.chartMetadata?.chartKey ===
              'TOP_REVIEWER_DISTRIBUTION' ? (
                <Box
                  height={
                    modifiedData?.data?.length <= 6
                      ? '300px'
                      : modifiedData?.data?.length > 6 &&
                        modifiedData?.data?.length <= 10
                      ? '500px'
                      : modifiedData?.data?.length > 10 &&
                        modifiedData?.data?.length <= 20
                      ? '800px'
                      : modifiedData?.data?.length > 20 &&
                        modifiedData?.data?.length <= 37
                      ? '1000px'
                      : `${modifiedData?.data?.length * 25}`
                    // the above is just to scale the number or entries rather than having a fixed size.
                  }
                >
                  <Chart
                    data={modifiedData?.data}
                    keys={modifiedData?.keys}
                    chartMetadata={modifiedData?.chartMetadata}
                    showLabel={true}
                    forDetails={true}
                    selectedTeam={selectedTeam}
                    selected={selected}
                    ticketType={ticketType}
                    repos={repos}
                  />
                </Box>
              ) : (
                <Chart
                  data={modifiedData?.data}
                  keys={modifiedData?.keys}
                  chartMetadata={modifiedData?.chartMetadata}
                  showLabel={true}
                  forDetails={true}
                  granularSlant={viewToggle === 'Day'}
                  selectedTeam={selectedTeam}
                  selected={selected}
                  ticketType={ticketType}
                  repos={repos}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      {imageDownloadLoading && <Loader />}
      {setGoalPopup.isOpen && (
        <SetGoalModal
          isOpen={setGoalPopup.isOpen}
          onClose={setGoalPopup.onClose}
          metricTitle={modifiedData?.chartMetadata?.chartTitle}
          queryKey={queryKey}
        />
      )}
    </>
  );
};

export default MetricDashboard;
