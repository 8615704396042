import { useCallback, useState } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import './chart.css';
import { useNavigate } from 'react-router';
import { PR_CHART_KEYS } from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';

interface ComboChartRechartsProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  forDetails?: boolean;
  ticketType?: string;
  selected?: any;
  repos?: string[];
}

export const HrComboChartRecharts = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  forDetails,
  ticketType,
  selected,
  repos,
}: ComboChartRechartsProps) => {
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={'#0095E6'}
          fontSize={7.5}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  const newKeys = keys?.sort((a: any, b: any) => b.key.localeCompare(a.key));

  const prsReviewedMax = Math.max(
    ...data.map((entry: any) => entry.prs_reviewed)
  );

  const xDomain = [0, prsReviewedMax];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          ref={chartref}
          data={data}
          margin={{
            top: 10,
            right: 35,
            left: 240,
            bottom: forDetails ? 0 : -45,
          }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            type="number"
            xAxisId={'bottom'}
            orientation="bottom"
            domain={[0, 100]}
            tickLine={false}
            tickFormatter={(value) => `${value}%`}
            style={{
              fontSize: 11,
              fontWeight: 500,
            }}
          />
          <XAxis
            type="number"
            tickLine={false}
            orientation="top"
            domain={xDomain}
            style={{
              fontSize: 11,
              fontWeight: 500,
            }}
            label={{
              value: 'PRs Reviewed',
              position: 'center',
              fontSize: 11,
              fontWeight: 500,
              dy: -15,
            }}
          />

          <YAxis
            tickLine={false}
            label={{
              value: chartMetadata.ylabel,
              angle: -90,
              position: 'insideTopLeft',
              fontSize: 11,
              fontWeight: 500,
              dx: -240,
              dy: 110,
            }}
            style={{
              fontSize: 11,
              fontWeight: 600,
            }}
            dataKey={chartMetadata.yAxis}
            type="category"
          />
          <Tooltip
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.zlabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                hideBtn={true}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ x: 100 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />

          {showLabel && (
            <Legend
              verticalAlign="top"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: 50,
                // transform: 'translateY(-50%)',
              }}
            />
          )}

          {newKeys?.map((el: any) =>
            el.key === 'yRollingAvg' ? (
              <Line
                key={el.key}
                type="monotone"
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                dot={false}
                activeDot={false}
                strokeWidth={2}
                strokeDasharray="3 6"
              />
            ) : el.chartType === 'line_chart' ? (
              <Line
                key={el.key}
                xAxisId={'bottom'}
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                strokeWidth={2}
                dot={{
                  fill: el.color,
                  stroke: el.color,
                  strokeWidth: 3,
                  r: 0,
                }}
              />
            ) : el.chartType === 'bar_chart' && el.key !== 'prs_reviewed' ? (
              // excludes the prs_reviewed bar chart.
              <Bar
                key={el.key}
                dataKey={el.key}
                name={el.name}
                barSize={20}
                fill={el.color}
                stackId={'a'}
                style={{ cursor: 'pointer' }}
                onClick={(event: any, payload: any) => {
                  handleModalOpen();
                  setClickedData({
                    name: event.contributor,
                    payload: {
                      startDate: event.startDate,
                      endDate: event.endDate,
                    },
                  });

                  setCurrentChartMeta(chartMetadata);
                }}
              />
            ) : (
              <></>
            )
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {[...PR_CHART_KEYS].includes(currentChartMeta?.chartKey) && isOpen && (
        <MetricSidebar
          openDrawer={isOpen}
          handleDrawerClose={handleModalClose}
          heading={chartMetadata.chartTitle}
          subHeading={`${moment(selected?.startDate).format(
            'DD MMM'
          )} - ${moment(selected?.endDate).format('DD MMM')}, ${
            clickedData?.name
          }`}
          payload={{
            ...clickedData,

            payload: {
              startDate: selected?.startDate,
              endDate: selected?.endDate,
            },
          }}
          currentChartMeta={currentChartMeta}
          filterBy={{
            author: clickedData?.name?.toLowerCase(),
            projectsFilter: repos,
            teamId: selectedTeam,
            sprintId: selected.sprintId,
          }}
          selectedTeam={selectedTeam}
        />
      )}
    </>
  );
};
