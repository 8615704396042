import { FC, useMemo, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DataGrid } from '../../../GridTable/DataGrid';
import { SlideBar } from '../../../appSlideBar';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #0095e6;
`;

interface ActiveDaysSideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  team: string;
  contributorData: any;
  isFetching: boolean;
  subHeading: string;
}

export const ActiveDaysSideBar: FC<ActiveDaysSideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  team,
  contributorData,
  isFetching,
  subHeading,
}) => {
  const rows: any[] = useMemo(() => contributorData || [], [contributorData]);

  const columnHelper = createColumnHelper<any>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        cell: (info) => {
          return (
            <StyledLink to={`/contributors/${info?.row?.original?.email}`}>
              <Text isTruncated width="250px">
                {info?.getValue()}
              </Text>
            </StyledLink>
          );
        },
        header: 'Email',
      }),
      columnHelper.accessor('activeDays', {
        cell: (info) => info?.getValue(),
        header: 'Active Days',
      }),
    ],
    [contributorData]
  );

  return (
    <SlideBar
      width={'440px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={`Average Active Days`}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Flex flexDirection="column">
          <DataGrid
            showLoader={isFetching}
            columns={columns}
            data={rows}
            maxH="42rem"
            sticky="none"
          />
        </Flex>
      }
    />
  );
};

export default ActiveDaysSideBar;
