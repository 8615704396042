import React, { FC, useMemo, useState } from 'react';
import {
  DataGrid,
  Loader,
  PeriodType,
  SlideBar,
} from '@devd-client/devd/components';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useContributorDetailGql } from '../../../graphql';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { apiClient } from '@devd-client/api';
import moment from 'moment';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #0095e6;
`;

interface SlideDrawerProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  team: string;
  selected: PeriodType;
}

const SlideDrawer: FC<SlideDrawerProps> = ({
  openDrawer,
  handleDrawerClose,
  team,
  selected,
}) => {
  const { data: contributorData, isLoading: contributorLoading } =
    useContributorDetailGql(
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      team,
      localStorage.getItem('role')
    );

  const rows: any[] = useMemo(
    () => contributorData?.data || [],
    [contributorData?.data]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          return (
            <StyledLink to={`/contributors/${info?.row?.original?.email}`}>
              {info?.getValue()}
            </StyledLink>
          );
        },
        header: 'NAME',
      }),

      columnHelper.accessor('email', {
        cell: (info) => {
          return (
            <StyledLink to={`/contributors/${info.getValue()}`}>
              {info?.getValue()}
            </StyledLink>
          );
        },
        header: 'EMAIL',
      }),
    ],
    [contributorData?.data]
  );

  const [pending, setPending] = useState(false);

  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window?.URL?.createObjectURL(blob);

    const a = document?.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${localStorage?.getItem('orgId')} - ${team === '' ? 'All' : team}`
    );
    a.click();
  };
  const downloadCSV = async () => {
    setPending(true);
    apiClient(`/v1/csv/contributors`, {
      method: 'POST',
      headers: {
        authorization: `bearer ${localStorage?.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orgId: localStorage.getItem('orgId'),
        startDate: selected.startDate,
        endDate: selected.endDate,
        teamId: team,
      }),
    })
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };
  return (
    <SlideBar
      width={'440px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={`Contributors Details ${
        team === '' ? '' : `for Team "${team}"`
      }`}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {moment(selected.startDate).format('DD MMM')} -{' '}
          {moment(selected.endDate).format('DD MMM')},{' '}
          {contributorData?.data?.length}{' '}
          {contributorData?.data?.length > 1 ? 'contributors' : 'contributor'}
        </Text>
      }
      content={
        <Flex flexDirection="column">
          <DataGrid
            showLoader={contributorLoading}
            columns={columns}
            data={rows}
            maxH="42rem"
            sticky="none"
          />
          <Button
            variant="tertiary"
            onClick={downloadCSV}
            mt={2}
            isDisabled={pending}
          >
            Download data
          </Button>
          {pending && <Loader />}
        </Flex>
      }
    />
  );
};

export default React.memo(SlideDrawer);
