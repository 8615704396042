import { apiClient } from '@devd-client/api';

export const getMetricInfo = (chartKey: string): Promise<any> => {
  return apiClient(
    `/v1/tooltip/org/${localStorage.getItem('orgId')}/metric/${chartKey}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};
