import { Box, Skeleton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { InfoSlideBar } from './InfoSlideBar';
import { useGetMetricInfo } from './apis';

interface SlideInfoDrawerProps {
  openDrawer: boolean;
  handelDrawerClose: () => void;
  title?: string;
  chartKey: string;
}

export const SlideInfoDrawer: React.FC<SlideInfoDrawerProps> = ({
  openDrawer,
  handelDrawerClose,
  title = '',
  chartKey,
}) => {
  const { data: metricData, isLoading } = useGetMetricInfo(chartKey);
  const [typedContent, setTypedContent] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  console.log(metricData);

  useEffect(() => {
    if (metricData?.data && currentIndex < metricData.data.length) {
      const typeCharacter = () => {
        const nextIndex = currentIndex + 1;
        const newContent = metricData.data.slice(currentIndex, nextIndex);

        if (newContent) {
          setTypedContent((prevContent) => prevContent + newContent);
          setCurrentIndex(nextIndex);
        }
      };

      const timeout = setTimeout(typeCharacter, 0.5);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [currentIndex, metricData?.data]);

  return (
    <InfoSlideBar
      width={'45vw'}
      openDrawer={openDrawer}
      handleDrawerClose={handelDrawerClose}
      sideBarHeading={title}
      sideBarSubHeading=""
      content={
        <Box
          border={'1px solid #4D4DFF'}
          borderRadius={'8px'}
          marginBottom={10}
          minHeight={'85vh'}
        >
          {isLoading || metricData?.data === undefined ? (
            <Box padding={4} display={'flex'} flexDirection={'column'} gap={1}>
              {Array.from(Array(20).keys()).map((_, index: number) => (
                <Skeleton key={index} height={'26px'} width={'100%'} />
              ))}
            </Box>
          ) : (
            <Box
              padding={4}
              fontSize={'14px'}
              dangerouslySetInnerHTML={{ __html: typedContent }}
              fontFamily={'inter'}
              sx={{
                h1: {
                  marginTop: '4px',
                  marginBottom: '2px',
                  fontWeight: 'bold',
                },
                ul: {
                  marginLeft: '20px',
                  listStyleType: 'disc',
                  padding: '5px 0 5px 0',
                },
                li: {
                  padding: '3px 0 3px 0',
                },
              }}
            />
          )}
        </Box>
      }
    />
  );
};
