import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  metricInfo: 'metricInfo',
};

export const useGetMetricInfo = (chartKey: string) => {
  return useQuery([QUERY_KEYS.metricInfo, chartKey], () =>
    ENDPOINTS.getMetricInfo(chartKey)
  );
};
