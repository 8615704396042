import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { DataTable } from './DataGrid';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import IndeterminateCheckbox from './IntermediateCheckbox';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  initiallySelectedRows: any;
  onSelectedRowsChange: any;
  data: any;
  label?: string;
  loading: boolean;
  setTriggerSubmit: (arg: boolean) => void;
};

export const ListSelect = ({
  isOpen,
  onClose,
  initiallySelectedRows,
  onSelectedRowsChange,
  data,
  label,
  loading,
  setTriggerSubmit,
}: Props) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [filteredData, setFilteredData] = useState(data);

  const [updateInitiallySelectedRows, setUpdateInitiallySelectedRows] =
    useState<any>(initiallySelectedRows);

  const [activeTag, setActiveTag] = useState<any>(0);
  const hasUrl = data?.some((item: any) => item.url);

  const tags = ['All', 'Selected', 'Unselected'];
  const columns: ColumnDef<any>[] = [
    {
      id: 'select',
      header: ({ table }) =>
        activeTag === 0 ? (
          <Tooltip label="Select all" hasArrow>
            <Box
              h={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <IndeterminateCheckbox
                checked={table.getIsAllRowsSelected()}
                onChange={(e) => {
                  table.toggleAllRowsSelected(e.currentTarget.checked);
                  if (e.currentTarget.checked) {
                    setUpdateInitiallySelectedRows(
                      table.getRowModel().flatRows.map((row: any) => ({
                        label: row.original.value,
                        value: row.original.value,
                        url: row.original.url,
                        key: row.original.key,
                      }))
                    );
                  } else {
                    setUpdateInitiallySelectedRows([]);
                  }
                }}
                aria-label="Select all rows"
              />
            </Box>
          </Tooltip>
        ) : (
          <></>
        ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          onChange={(e) => {
            row.toggleSelected(e.currentTarget.checked);

            if (e.currentTarget.checked) {
              setUpdateInitiallySelectedRows((prevData: any[]) => [
                ...prevData,
                hasUrl
                  ? {
                      label: row.original.value,
                      value: row.original.value,
                      url: row.original.url,
                      key: row.original.key,
                    }
                  : { label: row.original.value, value: row.original.value },
              ]);
            } else {
              setUpdateInitiallySelectedRows((prevData: any[]) =>
                hasUrl
                  ? prevData.filter((item) => item.key !== row.original.key)
                  : prevData.filter((item) => item.value !== row.original.value)
              );
            }
          }}
          aria-label="Select row"
        />
      ),
      size: 75,
    },
    {
      accessorKey: 'label',
      header: ({ column }) => (
        <Button
          variant="unstyled"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {label}
        </Button>
      ),
      size: hasUrl ? 375 : 800,
    },
  ];

  if (hasUrl) {
    columns.push({
      accessorKey: 'url',
      header: () => (
        <Text mt={4} fontWeight={600} fontSize={'16px'}>
          URL
        </Text>
      ),
      cell: ({ row }) => (
        <a
          href={row.original.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue' }}
        >
          {row.original.url}
        </a>
      ),
      size: 1000,
    });
  }

  const handleTagClick = (index: string) => {
    setActiveTag(index);
  };

  useEffect(() => {
    if (activeTag === 0) {
      setFilteredData(data);
    } else if (activeTag === 2) {
      if (hasUrl) {
        const rows = updateInitiallySelectedRows?.map((el: any) => el.key);
        setFilteredData(data?.filter((item: any) => !rows?.includes(item.key)));
      } else {
        const rows = updateInitiallySelectedRows?.map((el: any) => el.value);
        setFilteredData(
          data?.filter((item: any) => !rows?.includes(item.value))
        );
      }
    } else {
      if (hasUrl) {
        const rows = updateInitiallySelectedRows?.map((el: any) => el.key);
        setFilteredData(data?.filter((item: any) => rows?.includes(item.key)));
      } else {
        const rows = updateInitiallySelectedRows?.map((el: any) => el.value);
        setFilteredData(
          data?.filter((item: any) => rows?.includes(item.value))
        );
      }
    }

    if (hasUrl) {
      const rows = updateInitiallySelectedRows?.map((el: any) => el.key);
      setSelectedRows(data?.filter((item: any) => rows?.includes(item.key)));
    } else {
      const rows = updateInitiallySelectedRows?.map((el: any) => el.value);
      setSelectedRows(data?.filter((item: any) => rows?.includes(item.value)));
    }
  }, [data, activeTag, updateInitiallySelectedRows]);

  return (
    <Modal
      size="2xl"
      scrollBehavior="inside"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxH={'max-content'}>
        <ModalHeader fontFamily={'inter'}>Select {label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <DataTable
            columns={columns}
            data={filteredData || []}
            label={label}
            initiallySelectedRows={updateInitiallySelectedRows}
            activeTag={activeTag}
            handleTagClick={handleTagClick}
            tags={tags}
            loading={loading}
            hasUrl={hasUrl}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            fontFamily={'inter'}
            size="sm"
            variant="ghost"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="filled"
            onClick={() => {
              onSelectedRowsChange(selectedRows);
              setTriggerSubmit(true);
              onClose();
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ListSelect;
