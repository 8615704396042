import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToastHook } from '../../appToast';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAddFavouriteMutation,
  useTeamFavMetricsMutation,
} from '../../metricsCard/apis';
import { useEffect, useMemo, useState } from 'react';
import { useMetricsListData, useUpdateCustomDashboard } from '../api';
import { BiHelpCircle, BiSearch } from 'react-icons/bi';
import Loader from '../../appLoader';
import { useSearchParams } from 'react-router-dom';

interface AddMetricModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalHeading: string;
  bodyContent: React.ReactNode;
  hideBtn?: boolean;
  name?: string;
  queryKeys?: any;
  buttonText?: string;
  forTeam?: boolean;
  forDashbaord?: boolean;
  handleCreateMetricsClick?: any;
  currentDashboardMetrics?: any;
  hideCreateMetricBtn?: boolean;
  agreementSearchQuery?: string;
  setAgreementSearchQuery?: (agreement: string) => void;
}

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const AddMetricModal = ({
  isOpen,
  onClose,
  modalHeading,
  bodyContent,
  hideBtn,
  name,
  queryKeys,
  buttonText,
  forTeam,
  forDashbaord,
  handleCreateMetricsClick,
  currentDashboardMetrics,
  hideCreateMetricBtn,
  agreementSearchQuery,
  setAgreementSearchQuery,
}: AddMetricModalProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const teamName = forTeam ? decodeURIComponent(pathname.split('/')[2]) : '';

  const { data, isLoading } = useMetricsListData(teamName);

  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { mutate: handlePostMetrics } = useTeamFavMetricsMutation();
  const { mutate: addFavouriteMutation, isLoading: deleteMutationLoading } =
    useAddFavouriteMutation();
  const { mutate: updateDashboard } = useUpdateCustomDashboard();

  useEffect(() => {
    if (!isOpen) {
      setSelectedMetrics([]);
      setSearchQuery('');
    }
  }, [isOpen]);

  const toggleMetricSelection = (metricName: string) => {
    setSelectedMetrics((prevSelectedMetrics) => {
      if (prevSelectedMetrics.includes(metricName)) {
        return prevSelectedMetrics.filter((name) => name !== metricName);
      } else {
        return [...prevSelectedMetrics, metricName];
      }
    });
  };

  const addToFavourite = () => {
    addFavouriteMutation(
      { data: { name: selectedMetrics } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys?.favouritePage]);
          newToast({
            status: 'success',
            message: `Added to ${name}`,
          });
          onClose();
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message: err?.message ?? `Some error in Adding to ${name}`,
          });
        },
      }
    );
  };

  const addToFavTeams = () => {
    handlePostMetrics(
      {
        team: decodeURIComponent(pathname.split('/')[2]),
        metricsList: selectedMetrics,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys?.teamFavMetrics]);
          newToast({
            status: 'success',
            message: `Added to ${name}`,
          });
          onClose();
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message: err?.message ?? `Some error in Adding to ${name}`,
          });
        },
      }
    );
  };

  const addMetricToDashbaord = () => {
    const prevMetrics = currentDashboardMetrics?.layout?.metrics || [];
    const allMetrics = [
      ...prevMetrics,
      ...selectedMetrics.map((item: any) => ({ metric: item, position: null })),
    ];
    const uniqueMetricsMap = new Map<string, any>(
      allMetrics.map((item) => [item.metric, item])
    );
    const uniqueMetricsArray = Array.from(uniqueMetricsMap.values());

    updateDashboard(
      {
        name: currentDashboardMetrics?.key?.name,
        orgId: localStorage.getItem('orgId'),
        scope: 'org',
        layout: {
          ...currentDashboardMetrics.layout,
          metrics: uniqueMetricsArray,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys?.customDashboardsDetails]);
          newToast({
            status: 'success',
            message: `Added to ${searchParam.get('id')}`,
          });
          onClose();
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message:
              err?.message ??
              `Some error in Adding to ${searchParam.get('id')}`,
          });
        },
      }
    );
  };

  const handleHelpIconClick = (helpDoc: string) => {
    window.open(helpDoc, '_blank');
  };

  const filteredMetrics = useMemo(() => {
    if (!debouncedSearchQuery) {
      return data?.dto || [];
    }
    return data?.dto?.filter((metric: any) => {
      return metric.title
        .toLowerCase()
        .includes(debouncedSearchQuery.toLowerCase());
    });
  }, [debouncedSearchQuery, data]);

  const body = bodyContent ? (
    bodyContent
  ) : (
    <Grid
      templateColumns="repeat(3, 1fr)"
      gap={6}
      fontSize="16px"
      fontWeight="normal"
      py={2}
    >
      {filteredMetrics?.map((metric: any, i: number) => (
        <GridItem
          key={i}
          borderWidth="1px"
          borderRadius="md"
          p={4}
          w="100%"
          backgroundColor="#F7F7F7"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex align={'center'}>
              <Text fontFamily="heading" fontWeight="bold" color="text.primary">
                {metric?.title}
              </Text>
              {metric?.helpDoc && (
                <Box
                  as={BiHelpCircle}
                  onClick={() => handleHelpIconClick(metric?.helpDoc)}
                  _hover={{ cursor: 'pointer', color: 'primary' }}
                  transition="color 0.3s"
                  fontSize={'sm'}
                  ml={1}
                />
              )}
            </Flex>
            <input
              type="checkbox"
              checked={selectedMetrics.includes(
                metric?.name === 'CUSTOM' ? metric?.title : metric?.name
              )}
              onChange={() =>
                toggleMetricSelection(
                  metric?.name === 'CUSTOM' ? metric?.title : metric?.name
                )
              }
            />
          </Flex>
          <Text fontFamily="heading" fontSize="xs" color="text.secondary">
            {metric?.info}
          </Text>
        </GridItem>
      ))}
    </Grid>
  );

  const footerContent = (
    <Button
      mr={4}
      variant="filled"
      size="sm"
      onClick={
        forDashbaord && !forTeam
          ? addMetricToDashbaord
          : forTeam
          ? addToFavTeams
          : addToFavourite
      }
      isDisabled={!selectedMetrics?.length}
    >
      {buttonText}
    </Button>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size={'6xl'}
    >
      <ModalOverlay />
      {isLoading ? (
        <Loader />
      ) : (
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader
            alignItems="center"
            justifyContent={'space-between'}
            display={'flex'}
          >
            <Flex
              fontFamily="heading"
              fontSize="xl"
              fontWeight="semibold"
              color="text.primary"
            >
              {modalHeading}
              <InputGroup
                maxW="200px"
                ml={4}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <InputLeftElement
                  pointerEvents="none"
                  marginBottom={'4px'}
                  paddingBottom={'6px'}
                >
                  <BiSearch color={'#0095E6'} fontSize={'16px'} />
                </InputLeftElement>
                <Input
                  placeholder={
                    setAgreementSearchQuery
                      ? 'Search agreement'
                      : 'Search metrics'
                  }
                  value={
                    agreementSearchQuery ? agreementSearchQuery : searchQuery
                  }
                  onChange={(e) => {
                    if (setAgreementSearchQuery) {
                      setAgreementSearchQuery(e.target.value);
                    } else {
                      setSearchQuery(e.target.value);
                    }
                  }}
                  size="sm"
                  border={'1px solid #0095E6'}
                />
              </InputGroup>
              {!hideCreateMetricBtn && (
                <Button
                  ml={4}
                  onClick={() =>
                    navigate(
                      `add-metric${
                        searchParam.get('id')
                          ? `?id=${searchParam.get('id')}`
                          : ''
                      }`
                    )
                  }
                  size="sm"
                  variant={'filled'}
                >
                  + Create Metric
                </Button>
              )}
            </Flex>
          </ModalHeader>

          <ModalBody maxH={'80vh'} minH="480px" overflowY={'auto'}>
            {body}
          </ModalBody>
          {!hideBtn && <ModalFooter>{footerContent}</ModalFooter>}
        </ModalContent>
      )}
    </Modal>
  );
};

export default AddMetricModal;
