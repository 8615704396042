import React, { FC, Fragment, useCallback, useState } from 'react';
import { Paginate, PeriodType, SlideBar } from '@devd-client/devd/components';
import { Box, Divider, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useWorkingAgreementDetailGql } from '../../../graphql';
import { Link } from 'react-router-dom';
import moment from 'moment';

const formatLabel = (label: any) => {
  return label
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str: any) => str.toUpperCase());
};

interface SlideAgreementDrawerProps {
  openAgreementDrawer: boolean;
  handleAgreementDrawerClose: () => void;
  team: string;
  selected: PeriodType;
}

const SlideAgreementDrawer: FC<SlideAgreementDrawerProps> = ({
  openAgreementDrawer,
  handleAgreementDrawerClose,
  team,
  selected,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { data: workingAgreementData, isLoading: workingAgreementLoading } =
    useWorkingAgreementDetailGql(
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      team,
      currentPage,
      20
    );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <SlideBar
      width={'460px'}
      openDrawer={openAgreementDrawer}
      handleDrawerClose={handleAgreementDrawerClose}
      sideBarHeading={`Agreement Exception for Team "${
        team === '' ? 'All' : team
      }"`}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {moment(selected.startDate).format('DD MMM')} -{' '}
          {moment(selected.endDate).format('DD MMM')}, Agreement Exception (
          {workingAgreementData?.totalCount})
        </Text>
      }
      content={
        <Stack spacing={1}>
          {workingAgreementLoading &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {workingAgreementData?.data?.map(
            (item: any, idx: number, arr: any) => (
              <Fragment key={item?.id}>
                <Box p={2}>
                  <Stack spacing={1}>
                    <Flex justifyContent={'space-between'}>
                      <Box>
                        {item?.date && (
                          <Text
                            fontFamily="heading"
                            fontSize="xs"
                            color="text.secondary"
                            fontWeight="semibold"
                          >
                            {moment(item?.date).format('lll')}
                          </Text>
                        )}

                        <Link to={`${item?.link}`}>
                          <Text
                            fontSize="md"
                            fontFamily="heading"
                            textDecoration="underline"
                            color="gray.700"
                            fontWeight="medium"
                          >
                            {item?.description}
                          </Text>
                        </Link>
                      </Box>
                    </Flex>

                    <Flex align="center" flexWrap="wrap">
                      {Object?.entries(item)?.map(([key, value]: any) => {
                        if (key !== 'link') {
                          if (key === 'updatedDate') {
                            value = moment(value)?.format('lll');
                          }
                          return (
                            <Flex mr={3} key={key}>
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="semibold"
                                color="gray.600"
                                whiteSpace="nowrap"
                                mr={1}
                              >
                                {formatLabel(key)}
                              </Text>
                              <Text mt={'-3px'}>:</Text>
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="medium"
                                color="text.secondary"
                                ml={1}
                              >
                                {value}
                              </Text>
                            </Flex>
                          );
                        }

                        return null;
                      })}
                    </Flex>
                  </Stack>
                </Box>
                {idx !== arr?.length - 1 && <Divider />}
              </Fragment>
            )
          )}

          <Box my={'16px'}>
            {workingAgreementData?.data?.length > 1 && (
              <Paginate
                currentPage={workingAgreementData?.currentPage + 1}
                totalPageCount={workingAgreementData?.totalPages}
                pageSize={20}
                onPageChange={(page) => handlePageClick(page as number)}
              />
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default React.memo(SlideAgreementDrawer);
