import {
  AuthAction,
  AuthActionTypes,
  AuthState,
  authReducer,
} from '../reducer/authReducer';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
  graphQLClient,
  updateClientAPIWithRegion,
  updateClientGraphQLWithRegion,
} from '@devd-client/api';

import {
  Loader,
  useToastHook,
  useDashboardStore,
} from '@devd-client/devd/components';
import { auth } from '../helpers/auth';
import {
  absoluteClearInterval,
  absoluteSetInterval,
} from '../helpers/authUtils';
import { getUser } from '../api/endpoints';
import { useFetchOrdinals } from '../api';

export interface AuthProps {
  children?: React.ReactNode;
}

const initState = {
  user: null,
  authIsReady: false,
};

const nan = () => {
  return;
};

export const AuthContext = React.createContext<
  [AuthState, React.Dispatch<AuthAction>, () => void]
>([initState, nan, nan]);

export const AuthProvider = ({ children }: AuthProps) => {
  const [, setToken] = useState(null);
  const [newToast] = useToastHook();
  const [pending, setPending] = useState(true);
  const [state, dispatch] = React.useReducer(authReducer, initState);

  const handleLogout = async () => {
    // eslint-disable-next-line no-debugger
    dispatch({ type: AuthActionTypes.LOGOUT });
    localStorage.removeItem('token');
    localStorage.removeItem('authId');
    useDashboardStore.setState({
      selectedTeam: {
        value: '',
        label: 'All',
      },
      selected: {
        id: 0,
        startDate: '',
        endDate: '',
        duration: '',
        sprintId: '',
      },
      filterTitle: '',
      active: '',
    });
    localStorage.removeItem('team');
    localStorage.setItem('isLoggedIn', '' + false);
    localStorage.removeItem('region');
    updateClientGraphQLWithRegion(localStorage.getItem('region'));
    updateClientAPIWithRegion(localStorage.getItem('region'));
    localStorage.clear();
    await auth.signOut();
  };

  useEffect(() => {
    let isAvailable = true;
    dispatch({ type: AuthActionTypes.LOGOUT });
    /*
     * todo - Change types from any to right one.
     */
    auth.onAuthStateChanged((user: any) => {
      if (isAvailable) {
        //let userSessionTimeout = null;
        if (user) {
          user.getIdTokenResult(true).then(function (idTokenResult: any) {
            if (
              !idTokenResult.claims.orgId &&
              window.location.pathname.indexOf('/account/login') > -1
            ) {
              newToast({
                message:
                  'This user is not registered with any organization. Please signup at https://devdynamics.ai/signup',
                status: 'error',
              });
              handleLogout();
              return;
            }

            if (
              idTokenResult.token &&
              idTokenResult.claims.orgId &&
              (idTokenResult.claims.role === 'SUPPORT' ||
                idTokenResult.claims.role === 'EMPLOYER_ADMIN' ||
                idTokenResult.claims.role === 'EMPLOYER_READ' ||
                idTokenResult.claims.role === 'EMPLOYER_WRITE' ||
                idTokenResult.claims.role === 'EMPLOYEE' ||
                idTokenResult.claims.role === 'MANAGER')
            ) {
              localStorage.setItem('authId', user.uid);
              localStorage.setItem('token', idTokenResult.token);
              localStorage.setItem('role', idTokenResult.claims.role);
              graphQLClient.setHeader(
                'authorization',
                `bearer ${idTokenResult.token}`
              );

              getUser()
                .then((res: any) => {
                  localStorage.setItem('orgId', res.dto.org_id);
                  localStorage.setItem('orgName', res.dto.org_name);
                  localStorage.setItem('userId', res.dto.id);
                  res.dto.team && localStorage.setItem('team', res.dto.team);
                  res.dto.region &&
                    localStorage.setItem('region', res.dto.region);
                  updateClientGraphQLWithRegion(localStorage.getItem('region'));
                  updateClientAPIWithRegion(localStorage.getItem('region'));
                  dispatch({
                    type: AuthActionTypes.AUTH_READY,
                    payload: { ...user, ...res },
                  });
                })
                .catch((err: any) => {
                  newToast({ message: err?.message, status: 'error' });
                  handleLogout();
                })
                .finally(() => {
                  setPending(false);
                });

              setToken(idTokenResult.token);

              //dispatch({ type: AuthActionTypes.LOGOUT });
              const authTime = idTokenResult.claims.auth_time * 1000;
              const sessionDurationInMilliseconds = 1 * 60 * 1000; // 60 min
              const expirationInMilliseconds =
                sessionDurationInMilliseconds - (Date.now() - authTime);
            } else {
              setPending(false);
              setToken(null);
              handleLogout();
              newToast({
                message: 'User not authorised.',
                status: 'error',
              });
            }
            return idTokenResult;
          });
        } else {
          setToken(null);
          localStorage.setItem('token', '');
          setPending(false);
        }
      }
    });

    return () => {
      isAvailable = false;
    };
  }, []);

  useEffect(() => {
    const handle = absoluteSetInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken(true);
          localStorage.removeItem('token');
          localStorage.setItem('token', token);
          graphQLClient.setHeader('authorization', `bearer ${token}`);
        } catch (error) {
          handleLogout();
        }
      }
    }, 55 * 60 * 1000);

    return () => absoluteClearInterval(handle);
  }, []);

  if (pending) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={[state, dispatch, handleLogout]}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

declare global {
  interface Window {
    Intercom: any;
    analytics: any;
  }
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const [appState, , handleLogout] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useFetchOrdinals();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    window.analytics.identify(appState?.user?.dto?.id, {
      firstName: appState?.user?.dto?.firstName,
      lastName: appState?.user?.dto?.lastName,
      email: appState?.user?.email,
      orgId: appState?.user?.dto?.org_id,
    });
  }, [location]);

  useEffect(() => {
    if (
      data?.dto?.setupSteps.some(
        (item: any) =>
          item.accountIntegrationStatus === 'FAILED' ||
          item.accountIntegrationStatus === 'INIT'
      )
    ) {
      navigate('/onboarding');
    }
  }, [data]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    setTimeout(() => {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'diiw5xyw',
        name: appState?.user?.displayName,
        email: appState?.user?.email,
        created_at: appState?.user?.metadata?.createdAt,
      });
    }, 5000);

    return () => window.Intercom('shutdown');
  }, []);

  if (!appState.user) {
    return <Navigate to="/account/login" state={{ from: location }} replace />;
  }

  return children;
}
