import {
  Box,
  Button,
  Flex,
  Grid,
  Skeleton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import {
  useCodeStatsGql,
  useDoraChartTeamStatGql,
  useTeamStatGql,
} from '../../../graphql';
import { useLocation, useNavigate } from 'react-router';
import PrBarChart from './prBarChart';
import { DoraChart } from './doraChart';
import IncidentPieChart from './incidentPieChart';
import {
  AppTooltip,
  PeriodType,
  CodeWorkBreakdownPieChart,
} from '@devd-client/devd/components';
import { Link } from 'react-router-dom';

interface TeamStatsProps {
  selected: PeriodType;
}

const TeamStats = ({ selected }: TeamStatsProps) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { data: pullRequestStats, isLoading: pullRequestStatsLoading } =
    useTeamStatGql(
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      decodeURIComponent(pathname.split('/')[2]),
      'pr'
    );

  const { data: pieChartStats, isLoading: pieChartStatsLoading } =
    useTeamStatGql(
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      decodeURIComponent(pathname.split('/')[2]),
      'issue'
    );

  const { data: codeStats, isLoading: codeStatsLoading } = useCodeStatsGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname.split('/')[2])
  );

  const { data: doraChartStats, isLoading: doraChartStatsLoading } =
    useDoraChartTeamStatGql(
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      decodeURIComponent(pathname.split('/')[2])
    );

  return (
    <>
      {pullRequestStatsLoading ||
      pieChartStatsLoading ||
      doraChartStatsLoading ||
      codeStatsLoading ? (
        <Flex flexDirection="column" gap={6}>
          <Flex gap={6}>
            {Array.from(Array(2).keys()).map((_, index: number) => (
              <Box h={'240px'} w={'50%'} position={'relative'}>
                <Skeleton borderRadius="4px" height="240px" />
                <Spinner position={'absolute'} top={'45%'} left={'47%'} />
              </Box>
            ))}
          </Flex>
          <Flex gap={6}>
            {Array.from(Array(2).keys()).map((_, index: number) => (
              <Box h={'240px'} w={'50%'} position={'relative'}>
                <Skeleton borderRadius="4px" height="240px" />
                <Spinner position={'absolute'} top={'45%'} left={'47%'} />
              </Box>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Box>
          <Flex gap={6}>
            {pullRequestStats?.metricStats && !pullRequestStatsLoading ? (
              <PrBarChart
                pullRequestStats={pullRequestStats?.metricStats}
                selected={selected}
              />
            ) : (
              <Box
                w={'full'}
                borderWidth={1}
                borderColor="gray.300"
                borderRadius="12px"
                bg="#fff"
                p={5}
                minHeight="248px"
                border="1px solid #E3E6EA"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
                transition="all 0.2s"
                _hover={{
                  boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
                }}
              >
                <Flex justify="space-between" align="center">
                  <Text
                    fontWeight="semibold"
                    fontFamily="heading"
                    color="text.primary"
                    lineHeight="24px"
                    fontSize={'md'}
                  >
                    PR Activity
                  </Text>

                  {(pullRequestStats?.chartDataState === 'ERROR' ||
                    pullRequestStats?.chartDataState === 'NOT_CONFIGURED') && (
                    <AppTooltip
                      trigger={'hover'}
                      content={
                        <Flex>
                          <Text
                            color="text.primary"
                            fontFamily="heading"
                            fontSize="sm"
                            mr={1}
                          >
                            {pullRequestStats?.chartError?.message}
                          </Text>
                          <Link to={`${pullRequestStats?.chartError?.link}`}>
                            <Text
                              color="primary"
                              fontFamily="heading"
                              fontSize="sm"
                              textDecoration={'underline'}
                            >
                              Configure
                            </Text>
                          </Link>
                        </Flex>
                      }
                    >
                      <Box>
                        <Button
                          size={'xs'}
                          color={'error'}
                          border={'1px'}
                          borderColor={'error'}
                          backgroundColor={'white'}
                          fontFamily="heading"
                        >
                          {'why no data?'}
                        </Button>
                      </Box>
                    </AppTooltip>
                  )}
                </Flex>
              </Box>
            )}

            {pieChartStats?.metricStats && !pieChartStatsLoading ? (
              <IncidentPieChart
                pieChartStats={pieChartStats?.metricStats}
                selected={selected}
              />
            ) : (
              <Box
                w={'full'}
                borderWidth={1}
                borderColor="gray.300"
                borderRadius="12px"
                bg="#fff"
                minHeight="248px"
                border="1px solid #E3E6EA"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
                transition="all 0.2s"
                _hover={{
                  boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
                }}
              >
                <Flex justify="space-between" align="center">
                  <Text
                    fontWeight="semibold"
                    fontFamily="heading"
                    color="text.primary"
                    lineHeight="24px"
                    fontSize={'md'}
                  >
                    Issue Activity
                  </Text>

                  {(pieChartStats?.chartDataState === 'ERROR' ||
                    pieChartStats?.chartDataState === 'NOT_CONFIGURED') && (
                    <AppTooltip
                      trigger="hover"
                      content={
                        <Flex>
                          <Text
                            color="text.primary"
                            fontFamily="heading"
                            fontSize="sm"
                            mr={1}
                          >
                            {pieChartStats?.chartError?.message}
                          </Text>
                          <Link to={`${pieChartStats?.chartError?.link}`}>
                            <Text
                              color="primary"
                              fontFamily="heading"
                              fontSize="sm"
                              textDecoration={'underline'}
                            >
                              Configure
                            </Text>
                          </Link>
                        </Flex>
                      }
                    >
                      <Box>
                        <Button
                          size={'xs'}
                          color={'error'}
                          border={'1px'}
                          borderColor={'error'}
                          backgroundColor={'white'}
                          fontFamily="heading"
                        >
                          {'why no data?'}
                        </Button>
                      </Box>
                    </AppTooltip>
                  )}
                </Flex>
              </Box>
            )}
          </Flex>

          <Flex gap={6}>
            <Box
              borderWidth={1}
              borderColor="gray.300"
              borderRadius="12px"
              my={'20px'}
              bg="#fff"
              w="full"
              minHeight="300px"
              border="1px solid #E3E6EA"
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
              transition="all 0.2s"
              _hover={{
                boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
              }}
            >
              <Box p={5}>
                <Flex justify="space-between">
                  <Text
                    fontWeight="semibold"
                    fontFamily="heading"
                    color="text.primary"
                    lineHeight="24px"
                    fontSize={'md'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      navigate(
                        `/metrics/dora?duration=${selected?.duration}&end=${
                          selected?.endDate
                        }&start=${
                          selected?.startDate
                        }&team=${decodeURIComponent(pathname.split('/')[2])}`
                      );
                    }}
                    cursor="pointer"
                    textDecoration="none"
                    transition="text-decoration 0.2s ease-in-out"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    _focus={{
                      outline: 'none',
                      boxShadow: 'outline',
                    }}
                  >
                    DORA Activity
                  </Text>

                  {(doraChartStats?.chartDataState === 'ERROR' ||
                    doraChartStats?.chartDataState === 'NOT_CONFIGURED') && (
                    <AppTooltip
                      trigger="hover"
                      content={
                        <Flex>
                          <Text
                            color="text.primary"
                            fontFamily="heading"
                            fontSize="sm"
                            mr={1}
                          >
                            {doraChartStats?.chartError?.message}
                          </Text>
                          <Link to={`${doraChartStats?.chartError?.link}`}>
                            <Text
                              color="primary"
                              fontFamily="heading"
                              fontSize="sm"
                              textDecoration={'underline'}
                            >
                              Configure
                            </Text>
                          </Link>
                        </Flex>
                      }
                    >
                      <Box>
                        <Button
                          size={'xs'}
                          color={'error'}
                          border={'1px'}
                          borderColor={'error'}
                          backgroundColor={'white'}
                          fontFamily="heading"
                        >
                          {'why no data?'}
                        </Button>
                      </Box>
                    </AppTooltip>
                  )}
                </Flex>

                {doraChartStats?.metricStats && !doraChartStatsLoading && (
                  <DoraChart
                    doraChartStats={doraChartStats?.metricStats}
                    selected={selected}
                  />
                )}
              </Box>
            </Box>

            {(codeStats?.chartDataState === 'ERROR' ||
              codeStats?.chartDataState === 'NOT_CONFIGURED') && (
              <Box
                borderWidth={1}
                borderColor="gray.300"
                borderRadius="12px"
                my={'20px'}
                bg="#fff"
                minHeight="300px"
                w={'full'}
                border="1px solid #E3E6EA"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
                transition="all 0.2s"
                _hover={{
                  boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
                }}
              >
                <Box p={5}>
                  <Flex justify="space-between">
                    <Text
                      fontWeight="semibold"
                      fontFamily="heading"
                      color="text.primary"
                      lineHeight="24px"
                      fontSize={'md'}
                      cursor="pointer"
                      textDecoration="none"
                      transition="text-decoration 0.2s ease-in-out"
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      _focus={{
                        outline: 'none',
                        boxShadow: 'outline',
                      }}
                    >
                      Code Work Breakdown
                    </Text>

                    <AppTooltip
                      trigger="hover"
                      content={
                        <Flex>
                          <Text
                            color="text.primary"
                            fontFamily="heading"
                            fontSize="sm"
                            mr={1}
                          >
                            {codeStats?.chartError?.message}
                          </Text>
                          <Link to={`${codeStats?.chartError?.link}`}>
                            <Text
                              color="primary"
                              fontFamily="heading"
                              fontSize="sm"
                              textDecoration={'underline'}
                            >
                              Configure
                            </Text>
                          </Link>
                        </Flex>
                      }
                    >
                      <Box>
                        <Button
                          size={'xs'}
                          color={'error'}
                          border={'1px'}
                          borderColor={'error'}
                          backgroundColor={'white'}
                          fontFamily="heading"
                        >
                          {'why no data?'}
                        </Button>
                      </Box>
                    </AppTooltip>
                  </Flex>
                </Box>
              </Box>
            )}
            {codeStats?.metricStats && !codeStatsLoading && (
              <Box
                borderWidth={1}
                borderColor="gray.300"
                borderRadius="12px"
                my={'20px'}
                bg="#fff"
                minHeight="300px"
                w={'full'}
                border="1px solid #E3E6EA"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
                transition="all 0.2s"
                _hover={{
                  boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
                }}
              >
                <CodeWorkBreakdownPieChart
                  pieChartStats={codeStats?.metricStats}
                  selected={selected}
                  queryKey=""
                />
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default TeamStats;
