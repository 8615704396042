import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Input,
  HStack,
  Text,
  Tag,
  Flex,
  Button,
} from '@chakra-ui/react';
import Loader from '../../appLoader';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  initiallySelectedRows: any;
  label?: string;
  activeTag: any;
  handleTagClick: any;
  tags: string[];
  loading: boolean;
  hasUrl?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  initiallySelectedRows,
  label,
  activeTag,
  handleTagClick,
  tags,
  loading,
  hasUrl,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [rowSelection, setRowSelection] = React.useState({});
  const [filtering, setFiltering] = React.useState('');

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      globalFilter: filtering,
      columnFilters,
      rowSelection,
    },
    onGlobalFilterChange: setFiltering,
  });

  React.useEffect(() => {
    const mapData: any = {};
    data?.map((item: any, index) => {
      initiallySelectedRows?.map((selectedItem: any) => {
        if (hasUrl) {
          if (selectedItem?.key === item?.key) {
            mapData[index] = true;
          }
        } else {
          if (selectedItem?.value === item?.value) {
            mapData[index] = true;
          }
        }
      });
    });
    setRowSelection(mapData);
  }, [data]);

  const { rows } = table.getRowModel();
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });
  return (
    <Box>
      <HStack spacing={4} py={4}>
        <Input
          placeholder={`Search ${label}`}
          value={filtering}
          onChange={(e) => setFiltering(e.target.value)}
          maxW="sm"
        />
      </HStack>
      <Text
        mb={2}
        fontSize="sm"
        fontFamily="heading"
        color="text.secondary"
        fontWeight="medium"
      >
        {`Total ${data?.length || 0} ${label}`}
      </Text>
      <Flex justify="space-between" align="center">
        <Box mb={2}>
          {tags.map((tag: string, index: number) => (
            <Tag
              size="sm"
              key={index}
              bg={activeTag === index ? 'primaryBg' : 'white'}
              color={activeTag === index ? 'primary' : 'text.primary'}
              border="1px solid"
              borderColor={activeTag === index ? 'primary' : 'text.secondary'}
              borderRadius="full"
              px={4}
              py={2}
              m={1}
              cursor="pointer"
              fontFamily={'inter'}
              onClick={() => handleTagClick(index)}
            >
              {tag}
            </Tag>
          ))}
        </Box>
      </Flex>
      <Box borderWidth="1px" borderRadius="md" overflow="hidden" height="340px">
        <TableContainer
          ref={tableContainerRef}
          height="350px"
          overflowY={'auto'}
          overflowX={'auto'}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table>
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr style={{ display: 'flex' }} key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        style={{
                          display: 'flex',
                          width: header.getSize(),
                          borderRight: '1px solid #E5E7EB',
                        }}
                        key={header.id}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody
                style={{
                  display: 'grid',
                  height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                  position: 'relative', //needed for absolute positioning of rows
                }}
              >
                {table.getRowModel().rows?.length ? (
                  rowVirtualizer.getVirtualItems().map((virtualRow: any) => {
                    const row = rows[virtualRow.index];
                    return (
                      <Tr
                        key={row.id}
                        data-state={
                          row.getIsSelected() ? 'selected' : undefined
                        }
                        data-index={virtualRow.index} //needed for dynamic row height measurement
                        ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                          width: '100%',
                        }}
                      >
                        {row.getVisibleCells().map((cell: any) => (
                          <Td
                            style={{
                              display: 'flex',
                              width: cell.column.getSize(),
                              borderRight: '1px solid #E5E7EB',
                            }}
                            py={'3'}
                            fontFamily={'inter'}
                            key={cell.id}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={columns.length}
                      width="1000px"
                      textAlign="start"
                    >
                      No results.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Box>
      <HStack justifyContent="space-between" pt={4}>
        <Text fontFamily={'inter'} fontSize="sm" color="gray.500">
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </Text>
      </HStack>
    </Box>
  );
}
