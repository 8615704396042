import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Stack,
  Text,
  Textarea,
  useTheme,
} from '@chakra-ui/react';
import {
  eliminateHtmlTags,
  ToggleDays,
  eliminateSpecialCharacters,
} from '@devd-client/devd/components';
import { Select } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { BsAsterisk } from 'react-icons/bs';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { colorStyles } from '../../../helpers/teams.utils';
import { timeRangeOptions } from '../../../helpers/time-range';
import { useNavigate, useLocation } from 'react-router';

type Props = {
  teamName: string;
  setTeamName: (teamName: string) => void;
  teamDescription: string;
  setTeamDescription: (teamDescription: string) => void;
  parentTeam: any;
  setParentTeam: (parentTeam: any) => void;
  startTime: string;
  setStartTime: (startTime: string) => void;
  endTime: string;
  setEndTime: (endTime: string) => void;
  tz: string;
  setTz: (tz: string) => void;
  days: boolean[];
  setDays: (days: boolean[]) => void;
  defaultData: any;
  parentTeams: any;
  handleSubmit: any;
  setIsNewTeam: any;
};

const TeamDetails = ({
  setIsNewTeam,
  teamName,
  setTeamName,
  teamDescription,
  setTeamDescription,
  parentTeam,
  setParentTeam,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  tz,
  setTz,
  days,
  setDays,
  defaultData,
  parentTeams,
  handleSubmit,
}: Props) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const maxCharacterLimit = 40;

  useEffect(() => {
    setIsNewTeam(state ? true : false);
  }, [state]);

  const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = eliminateSpecialCharacters(e.target.value);
    if (newValue.length <= maxCharacterLimit) {
      setTeamName(eliminateHtmlTags(newValue));
    }
  };

  const formatOptionLabel = ({ value, label }: any) => {
    if (value === '') {
      return <span>&nbsp;</span>;
    }
    return label;
  };

  const handleCancelClick = () => {
    setTeamName(defaultData?.key?.name || '');
    setTeamDescription(defaultData?.description || '');

    setParentTeam(
      defaultData?.parentTeam
        ? {
            value: defaultData?.parentTeam,
            label: defaultData?.parentTeam,
          }
        : { value: '', label: '' }
    );
    setStartTime(defaultData?.workTiming?.startTime || '09:00');
    setEndTime(defaultData?.workTiming?.endTime || '18:00');
    setTz(
      defaultData?.workTiming?.timeZone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    setDays(
      defaultData?.workTiming?.days || [
        false,
        true,
        true,
        true,
        true,
        true,
        false,
      ]
    );
  };

  return (
    <>
      <Stack mt="20px" w={'40%'} pr={5} spacing={4}>
        <Stack>
          <Flex align="center">
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
            >
              Team name
            </Text>
            <BsAsterisk color={'red'} size="8px" />
          </Flex>
          <Input
            disabled={defaultData ? true : false}
            placeholder="Team Name"
            value={teamName}
            onChange={(e) => {
              handleTeamNameChange(e);
            }}
            isRequired
          />
          <Text fontSize="sm" color="gray.500">
            {teamName.length}/{maxCharacterLimit}
          </Text>
        </Stack>

        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Description (optional)
          </Text>

          <Textarea
            placeholder="Team Description"
            size="sm"
            height={'100px'}
            resize={'vertical'}
            value={teamDescription}
            onChange={(e) => {
              setTeamDescription(eliminateHtmlTags(e.target.value));
            }}
          />
        </Stack>

        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Choose Parent Team
          </Text>
          <Box w="full">
            <Select
              useBasicStyles
              placeholder="Choose Parent Team"
              value={
                parentTeam?.value === null || parentTeam?.value === ''
                  ? null
                  : parentTeam
              }
              onChange={(e: any) => {
                setParentTeam({ value: e.value, label: e.value });
              }}
              menuPlacement="top"
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                parentTeams?.dto?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                }))
              )}
            />
          </Box>
        </Stack>

        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Work Timings
          </Text>
          <Flex gap={4}>
            <Box w="full">
              <Select
                placeholder="Start Time"
                onChange={(e: any) => {
                  setStartTime(e.value);
                }}
                menuPlacement="top"
                useBasicStyles
                value={{
                  value: startTime ? startTime : 'Select',
                  label: startTime ? startTime : 'Select',
                }}
                options={timeRangeOptions.map((time: any) => {
                  return {
                    value: time,
                    label: time,
                  };
                })}
              />
            </Box>
            <Box w={'full'}>
              <Select
                useBasicStyles
                placeholder="End Time"
                onChange={(e: any) => {
                  setEndTime(e.value);
                }}
                menuPlacement="top"
                value={{
                  value: endTime ? endTime : 'Select',
                  label: endTime ? endTime : 'Select',
                }}
                options={timeRangeOptions.map((time: any) => {
                  return {
                    value: time,
                    label: time,
                  };
                })}
              />
            </Box>
          </Flex>
        </Stack>

        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Time Zone
          </Text>

          <TimezoneSelect
            value={tz}
            onChange={(e) => {
              setTz(e.value as any);
            }}
            menuPlacement="top"
            styles={colorStyles}
            defaultValue={tz}
            labelStyle="abbrev"
            timezones={{
              ...allTimezones,
              'America/Lima': 'Pittsburgh',
              'Europe/Berlin': 'Frankfurt',
              'Europe/Paris': 'Paris',
              'Asia/Tokyo': 'Tokyo',
            }}
          />
        </Stack>

        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Working Days
          </Text>

          <ToggleDays days={days} setDays={setDays} />
        </Stack>
      </Stack>
      <HStack mt={6}>
        <Button variant="secondary" size="sm" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button variant="filled" size="sm" type="submit" onClick={handleSubmit}>
          {defaultData?.key?.name ? 'Update Team' : 'Save & Add Members'}
        </Button>
      </HStack>
    </>
  );
};

export default TeamDetails;
