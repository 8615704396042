import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, IconButton } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import { apiClient } from '@devd-client/api';
import {
  ACTIVE_DAYS_CHARTS,
  AuthorIdFilter,
  CFR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  ISSUE_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_CHART_KEYS,
  REQUIREMENT_STABILITY_CHARTS,
  TeamFilter,
} from '../shared/utils';
import moment from 'moment';

interface SlideDrawerProps {
  sideBarHeading: string;
  sideBarSubHeading: React.ReactNode;
  openDrawer: boolean;
  content?: React.ReactNode;
  handleDrawerClose: () => void;
  width?: string;
  currentChartMeta?: any;
  startDate?: string;
  endDate?: string;
  filterBy?: AuthorIdFilter | TeamFilter;
  hideDownload?: boolean;
}

type SideDrawerProps = {
  show: boolean;
  width?: string;
};

const SideDrawerContainer = styled.div<SideDrawerProps>`
  position: fixed;
  height: 100%;
  background: #fff;
  padding: 12px;
  top: 0;
  right: 0;
  width: ${({ width }) => width || '350px'};
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: ${({ show }) =>
    show ? 'transform: translateX(0%)' : 'translateX(120%)'};
  transition: transform 0.3s ease-out;
  z-index: 999;
  overflow-x: hidden;
  margin-top: 0 !important;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5px;
  margin-top: 5px;
`;

const Heading = styled.h1`
  font-size: 18px;
  font-family: 'Inter';
  color: '#000';
  font-weight: 700;
`;

const SideDrawerBody = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 88%;
  overflow-y: auto;
`;

export const SlideBar: FC<SlideDrawerProps> = ({
  openDrawer,
  content,
  handleDrawerClose,
  width,
  currentChartMeta,
  startDate,
  endDate,
  filterBy,
  sideBarHeading,
  sideBarSubHeading,
  hideDownload,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const [pending, setPending] = useState(false);

  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${
        currentChartMeta.chartTitle === 'Change Failure Rate'
          ? 'CHANGE_FAILURE_RATE'
          : currentChartMeta?.chartKey === 'CUSTOM'
          ? currentChartMeta?.chartTitle
          : currentChartMeta?.chartKey
      }: ${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
        'DD MMM'
      )}`
    );
    a.click();
  };

  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v1/csv/${
        [...PR_CHART_KEYS, ...PR_AGEING_CHARTS, ...ACTIVE_DAYS_CHARTS].includes(
          currentChartMeta?.chartKey
        )
          ? 'pr'
          : [...DEPLOY_CHART_KEYS].includes(currentChartMeta?.chartKey)
          ? 'deployment'
          : [...CFR_CHART_KEYS].includes(currentChartMeta?.chartKey)
          ? 'cfr'
          : [
              ...ISSUE_CHART_KEYS,
              ...ISSUE_AGE_CHART_KEYS,
              ...REQUIREMENT_STABILITY_CHARTS,
            ].includes(currentChartMeta?.chartKey)
          ? 'issue'
          : ''
      }/metric/${
        currentChartMeta.chartTitle === 'Change Failure Rate'
          ? 'CHANGE_FAILURE_RATE'
          : currentChartMeta?.chartKey
      }`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          orgId: `${localStorage.getItem('orgId')}`,
          startDate: startDate,
          endDate: endDate,
          ...filterBy,
          vsmAtrributes: { age: filterBy?.age },
        }),
      }
    )
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const downloadCustomCSV = async () => {
    setPending(true);
    const chartTitle = encodeURIComponent(currentChartMeta?.chartTitle);
    apiClient(`/v1/csv/custom/metric/${chartTitle}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        ...filterBy,
        vsmAtrributes: { age: filterBy?.age },
      }),
    })
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      handleDrawerClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <SideDrawerContainer ref={myRef} show={openDrawer} width={width}>
      <CloseButtonContainer>
        <Flex flexDir={'column'}>
          <Heading>{sideBarHeading}</Heading>
          {sideBarSubHeading && sideBarSubHeading}
        </Flex>
        <IconButton
          isRound
          bg="transparent"
          onClick={handleDrawerClose}
          aria-label="close"
          size="sm"
          icon={<AiOutlineClose fontSize="18" />}
        />
      </CloseButtonContainer>
      <SideDrawerBody>{content}</SideDrawerBody>

      {[
        ...PR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...PR_AGEING_CHARTS,
        ...ISSUE_AGE_CHART_KEYS,
        ...REQUIREMENT_STABILITY_CHARTS,
        ...ACTIVE_DAYS_CHARTS,
      ].includes(currentChartMeta?.chartKey)
        ? !hideDownload && (
            <Button
              w={'full'}
              variant="tertiary"
              onClick={downloadCSV}
              mt={2}
              isLoading={pending}
            >
              Download data
            </Button>
          )
        : currentChartMeta?.chartKey === 'CUSTOM' && (
            <Button
              w={'full'}
              variant="tertiary"
              onClick={downloadCustomCSV}
              mt={2}
              isLoading={pending}
            >
              Download data
            </Button>
          )}
    </SideDrawerContainer>
  );
};
