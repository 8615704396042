/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useCallback, useEffect, useState } from 'react';
import { useChartTableGql } from '../../graphql';
import {
  ACTIVE_DAYS_CHARTS,
  AuthorIdFilter,
  CONTRIBUTOR_CHART_KEYS,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  ISSUE_CHART_KEYS,
  MEMBER_CHART_KEYS,
  QUALITY_CHART_KEYS,
  TeamFilter,
  WELLBEING_CHART_KEYS,
} from '../../../shared/utils';
import ContributorSideBar from './contributorSideBar';
import WellbeingMemberSideBar from './wellbeing-member-SideBar';
import QualitySideBar from './qualitySideBar';
import DefaultSideBar from './defaultSideBar';
import ActiveDaysSideBar from './ActiveDaysSideBar';
import DependencyVulnerabilitySidebar from './dependencyVulnerabilitySidebar';

interface MetricSidebarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  heading: string;
  subHeading: string;
  currentChartMeta: any;
  selectedTeam?: string;
  filterBy: AuthorIdFilter | TeamFilter;
  payload: any;
  querykey?: string;
  repoTitle?: boolean;
}

export const MetricSidebar: FC<MetricSidebarProps> = ({
  openDrawer,
  handleDrawerClose,
  heading,
  subHeading,
  currentChartMeta,
  selectedTeam,
  filterBy,
  payload,
  querykey,
  repoTitle,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const startDate =
    payload && Array.isArray(payload)
      ? payload[0].payload.startDate
      : payload?.payload?.startDate;
  const endDate =
    payload && Array.isArray(payload)
      ? payload[0].payload.endDate
      : payload?.payload?.endDate;

  const { data, isFetching } = useChartTableGql(
    startDate,
    endDate,
    currentChartMeta?.chartKey === 'TOP_REVIEWER_DISTRIBUTION'
      ? 'PR_REVIEWED'
      : currentChartMeta?.chartKey === 'PR_CYCLE_TIME_BREAKDOWN'
      ? 'PR_CYCLE_TIME'
      : currentChartMeta?.chartKey === 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
      ? 'DEPLOYMENT_FREQUENCY'
      : currentChartMeta?.chartKey === 'LEAD_TIME_BREAKDOWN'
      ? 'LEAD_TIME_FOR_CHANGE'
      : currentChartMeta?.chartKey === 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
      ? 'MEAN_TIME_TO_RECOVERY'
      : currentChartMeta?.chartKey === 'CHANGE_HEALTH'
      ? 'CHANGE_FAILURE_RATE'
      : currentChartMeta?.chartKey === 'CUSTOM'
      ? currentChartMeta?.chartTitle
      : payload?.name?.includes('New') &&
        QUALITY_CHART_KEYS?.includes(currentChartMeta?.chartKey)
      ? `NEW_${currentChartMeta?.chartKey}`
      : ISSUE_CHART_KEYS?.includes(currentChartMeta?.chartKey) &&
        payload?.name === 'Total Issues'
      ? 'TASK_CLOSE'
      : ISSUE_CHART_KEYS?.includes(currentChartMeta?.chartKey) &&
        payload?.name === 'Reopened Issues'
      ? 'ISSUE_REOPENED'
      : currentChartMeta?.chartKey,
    filterBy,
    currentPage,
    20,
    currentChartMeta?.detailEntity ? currentChartMeta.detailEntity : ''
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [openDrawer]);

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return localStorage.getItem('role') === 'EMPLOYEE' ? null : (
    <>
      {[...CONTRIBUTOR_CHART_KEYS].includes(currentChartMeta?.chartKey) ? (
        <ContributorSideBar
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          team={selectedTeam!}
          contributorData={data}
          loading={isFetching}
          repoTitle={repoTitle}
        />
      ) : [...ACTIVE_DAYS_CHARTS].includes(currentChartMeta?.chartKey) ? (
        <ActiveDaysSideBar
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          team={selectedTeam!}
          contributorData={data}
          isFetching={isFetching}
          subHeading={subHeading}
        />
      ) : [...WELLBEING_CHART_KEYS, ...MEMBER_CHART_KEYS].includes(
          currentChartMeta?.chartKey
        ) ? (
        <WellbeingMemberSideBar
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          wellbeingMemberData={data}
          heading={heading}
          subHeading={subHeading}
          isFetching={isFetching}
          handlePageClick={handlePageClick}
        />
      ) : [...QUALITY_CHART_KEYS].includes(currentChartMeta?.chartKey) ? (
        <QualitySideBar
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          qualityData={data}
          heading={heading}
          subHeading={subHeading}
          isFetching={isFetching}
          handlePageClick={handlePageClick}
        />
      ) : [...DEPENDENCY_VULNERABILITIES_CHART_KEYS].includes(
          currentChartMeta?.chartKey
        ) ? (
        <DependencyVulnerabilitySidebar
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          dependencyVulnerabilityData={data}
          heading={heading}
          subHeading={subHeading}
          isFetching={isFetching}
          handlePageClick={handlePageClick}
        />
      ) : (
        <DefaultSideBar
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          defaultData={data}
          heading={heading}
          subHeading={subHeading}
          isFetching={isFetching}
          handlePageClick={handlePageClick}
          currentChartMeta={currentChartMeta}
          querykey={querykey}
          startDate={startDate}
          endDate={endDate}
          filterBy={filterBy}
        />
      )}
    </>
  );
};

export default MetricSidebar;
