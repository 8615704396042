/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  AppTooltip,
  InsightIcon,
  PeriodType,
} from '@devd-client/devd/components';
import { useInsightMetricGql } from '../../../graphql';
import ReactHtmlParser from 'html-react-parser';
import { BsArrowRight } from 'react-icons/bs';
import { beforeStyles, hoverBeforeStyles } from '../InsightCards.utils';
import { Link as NavLink } from 'react-router-dom';

interface QualityCardProps {
  team: string;
  selected: PeriodType;
}

export const QualityCard: React.FC<QualityCardProps> = ({ team, selected }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const qualityPopover = useDisclosure();

  const { data: qualityData, isLoading } = useInsightMetricGql(
    'QUALITY_CARD',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const progressData = qualityData?.data?.map((group: any) => {
    const totalValue = group?.reduce(
      (acc: any, item: any) => acc + parseInt(item.value),
      0
    );

    // Add the "total" key to each item in the group
    return group?.map((item: any) => ({
      ...item,
      total: totalValue?.toString(),
    }));
  });

  return (
    <>
      {isLoading ? (
        <Box position={'relative'} minWidth="100%" height="248px">
          <Skeleton h="248px" w="100%" />
          <Spinner position={'absolute'} top={'40%'} left={'43%'} />
        </Box>
      ) : qualityData?.chartDataState === 'NO_INTEGRATION' ? (
        <Box
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          position="relative"
          minH="248px"
          border="1px solid #E3E6EA"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            height="100%"
            objectFit="contain"
            src={qualityData?.chartMetadata?.zsImage || ''}
            style={{ position: 'relative' }}
          />
          {isHovered && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius="4px"
              transition="background-color 0.3s"
            >
              <Button
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                onClick={() => navigate('/settings/integrations')}
              >
                {`Connect Github & Jira`}
              </Button>
            </Box>
          )}
        </Box>
      ) : qualityData?.chartDataState === 'INTEGRATED' ? (
        <Flex
          direction="column"
          p={3}
          w="full"
          minW="230px"
          minH="370px"
          borderRadius="4px"
          justifyContent={'space-between'}
          border="1px solid #dde0e4"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
        >
          <Flex direction="column" mb={4}>
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {qualityData?.chartMetadata?.chartTitle}
              </Text>

              <Flex
                as={NavLink}
                to={`/metrics/quality?${window.location.href.split('?')[1]}`}
                align="center"
                justify="space-between"
                color="text.secondary"
                cursor="pointer"
                position="relative"
                fontSize="sm"
                _hover={{ _before: hoverBeforeStyles }}
                _before={beforeStyles}
              >
                <Text
                  fontSize="xs"
                  fontWeight="semibold"
                  transition="all 300ms linear"
                  mr={1}
                >
                  View Details
                </Text>
                <BsArrowRight />
              </Flex>
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {qualityData?.chartMetadata?.info}
            </Text>
          </Flex>

          {progressData?.map((e: any, i: number) => (
            <React.Fragment key={i}>
              <Flex direction="column">
                <Flex mt="5px" flexWrap="wrap" fontSize="12px">
                  {e?.map((item: any, index: number) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="flex-start"
                      mr={3}
                      mb={1}
                    >
                      <Box
                        bg={item?.color}
                        borderRadius="full"
                        boxSize="8px"
                        mr="4px"
                        mt={1}
                      />
                      <Flex>
                        <Text
                          color="text.secondary"
                          fontFamily="heading"
                          fontSize="xs"
                        >
                          {item?.key}:
                        </Text>
                        <Text
                          color="text.secondary"
                          fontFamily="heading"
                          fontWeight="semibold"
                          fontSize="xs"
                        >
                          &nbsp;
                          {Number(item?.value)}
                        </Text>
                      </Flex>
                    </Box>
                  ))}
                </Flex>
                <Flex
                  w="full"
                  h="32px"
                  borderRadius="2px"
                  borderWidth={'0.5px'}
                  flexDirection={'column'}
                  mb={2}
                  overflow="hidden"
                  // bg="#f1f1f1"
                >
                  {e?.map((item: any, progressBarIndex: any) => (
                    <Tooltip
                      hasArrow
                      key={`progress-${progressBarIndex}`}
                      label={`${Number(item?.value)}`}
                    >
                      <Flex
                        key={progressBarIndex}
                        align="center"
                        w={`${Number((item?.value / item?.total) * 100).toFixed(
                          0
                        )}%`}
                        h="100%"
                        bg={item?.color}
                        mr="1px"
                      ></Flex>
                    </Tooltip>
                  ))}
                </Flex>
              </Flex>
            </React.Fragment>
          ))}

          <Divider my={2}></Divider>

          <Flex direction="column">
            <Flex align="center">
              <InsightIcon width="12px" height="12px" />
              <Text
                ml={1}
                fontFamily="heading"
                fontSize="md"
                color="text.primary"
                fontWeight="medium"
              >
                Insight
              </Text>
            </Flex>
            <Flex direction="column">
              {qualityData?.insights?.map((insight: string, index: number) => (
                <Box
                  key={index}
                  fontSize="sm"
                  fontFamily="heading"
                  color="text.secondary"
                >
                  {ReactHtmlParser(insight)}
                </Box>
              ))}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          direction="column"
          p={3}
          w="full"
          minW="230px"
          minH="370px"
          borderRadius="4px"
          justifyContent={'space-between'}
          border="1px solid #dde0e4"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
        >
          <Flex direction="column" mb={4}>
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {`Quality`}
              </Text>
              {(qualityData?.chartDataState === 'ERROR' ||
                qualityData?.chartDataState === 'NOT_CONFIGURED') && (
                <AppTooltip
                  trigger="hover"
                  content={
                    <Flex>
                      <Text
                        color="text.primary"
                        fontFamily="heading"
                        fontSize="sm"
                        mr={1}
                      >
                        {qualityData?.chartError?.message}
                      </Text>
                      <NavLink to={`${qualityData?.chartError?.link}`}>
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </NavLink>
                    </Flex>
                  }
                >
                  <Box>
                    <Button
                      size={'xs'}
                      color={'error'}
                      border={'1px'}
                      borderColor={'error'}
                      backgroundColor={'white'}
                      fontFamily="heading"
                    >
                      {'why no data?'}
                    </Button>
                  </Box>
                </AppTooltip>
              )}
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {`Comparison between different metrics`}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};
